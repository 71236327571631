import * as React from "react"

import Layout from "../templates/Layout"
import Seo from "../components/seo"
import SeoImg from '../assets/sh_opengraph.jpg'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <img style={{visibility:'hidden'}} src={SeoImg} />
  </Layout>
)

export default NotFoundPage
